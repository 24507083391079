import React from "react";
import { IMaskMixin, IMask } from "react-imask";
import Label from "./Label";

const maskPattern = [
  {
    mask   : "DD.MM.YYYY",
    lazy   : true,
    blocks : {

      DD: {
        mask : IMask.MaskedRange,
        from : 1,
        to   : 31
      },

      MM: {
        mask : IMask.MaskedRange,
        from : 1,
        to   : 12
      },

      YYYY: {
        mask : IMask.MaskedRange,
        from : 1900,
        to   : 2012
      }
    }
  }
];

const IMaskInput = IMaskMixin(({ inputRef, ...props }) => (
  <input ref={inputRef as any} {...props} />
));

interface Props {
    name: string;
    label: string;
    onChange: (v: string) => void;
    defaultValue?: string;
    errormessage?: string;
    value: string;
}

const MaskedInput = ({ defaultValue, onChange, value, ...props }: Props) => {
  const handleAccept = (v: string) => {
    onChange(v);
  };

  return (

    <div className={`relative w-full`}>
      <Label htmlFor={props.name} text={props.label} />
      <IMaskInput
        {...props}
        mask={maskPattern}
        onAccept={handleAccept}
        placeholder={"DD.MM.YYYY"}
        value={value}
        type="text"
        className="shadow-sm focus:ring-brand-dark focus:border-brand-dark block w-full sm:text-base border-dark-grey border-opacity-75  rounded"
      />
      <div className="ml-2">
        {props.errormessage && (
          <label className="text-xs text-warning-red">
            {props.errormessage}
          </label>
        )}
      </div>
    </div>

  );
};

export default MaskedInput;