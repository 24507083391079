import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { countries, Country } from "data/countries";
import { countries as countriesDe } from "data/countriesDe";
import { useRouter } from "next/router";
import { Fragment, useCallback, useEffect, useState } from "react";
import Label from "./Label";

interface CountrySelectProps {
    label: string;
    name: string;
    errorMessage?: string;
    onChange: (value: string) => void;
    value: string;
}

const CountrySelect = ({ label, name, errorMessage, onChange, value }: CountrySelectProps) => {
  const [initialValue, setInitialValue] = useState<Country>();
  const [selected, setSelected] = useState<Country | null>(initialValue || null);
  const [query, setQuery] = useState("");
  const router = useRouter();

  const currentLang = router.locale;
  const countryListSet = currentLang === "de" ? countriesDe : countries;

  const handleChange = useCallback((value: any) => {
    onChange(value.code);
    setSelected(value);
  }, [onChange]);

  const countryCodeToCountryName = useCallback((code: string) => {
    return countryListSet.find((c) => c.code === code);
  }, [countryListSet]);

  useEffect(() => {
    const country = countryCodeToCountryName(value);

    if (!country) {
      setInitialValue({ code: "", name: "" });
      return;
    }
    setInitialValue(country);
  }, [countryCodeToCountryName, countryListSet, value]);

  const filteredData =
  query === ""
    ? countryListSet
    : countryListSet.filter((d) =>
      d.name
        .toLowerCase()
        .replace(/\s+/g, "")
        .includes(query.toLowerCase().replace(/\s+/g, ""))
    );

  return (
    <>
      <Combobox value={selected || initialValue} onChange={handleChange}>
        <div className="relative mt-1">
          <Label htmlFor={name} text={label} />
          <div className="relative w-full">
            <Combobox.Input
              className="shadow-sm focus:ring-brand-dark focus:border-brand-dark block w-full sm:text-base border-dark-grey border-opacity-75 rounded"
              displayValue={(country: Country) => country?.name}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-brand-dark focus:ring-0"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 z-50 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredData?.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredData?.map((data) => (
                  <Combobox.Option
                    key={data.code}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-light-grey text-black" : "text-black"
                      }`
                    }
                    value={data}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {data.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-black" : "text-black"
                            }`}
                          >
                            <CheckIcon className="h-4 w-4" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      <div className="ml-2">
        {errorMessage && (
          <label className="text-xs text-warning-red">
            {errorMessage}
          </label>
        )}
      </div>
    </>
  );
};

export default CountrySelect;