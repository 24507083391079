import React, { forwardRef } from "react";

interface Props {
    label?: string;
    description?: string;
    value: string;
    name: string;
    onChange: (...event: any[]) => void;
    onBlur: (...event: any[]) => void;
    error?: boolean;
    checked?: boolean;
}

const RadioButton = forwardRef<HTMLInputElement, Props>(
  ({ name, label, onChange, onBlur, value, checked, error = false }, ref) => {
    return (

      <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-4">
        <div className="flex items-center">
          <input
            ref={ref}
            name={name}
            type="radio"
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            checked={checked}
            className="focus:ring-brand-dark h-[17px] w-[17px] text-brand-dark border-dark-grey border-opacity-75 shadow"
          />
          <label className="ml-1 sm:ml-1.5 block text-xs sm:text-sm font-medium text-black">
            {label}
          </label>
        </div>
        {error && (
          <label className="text-xs text-warning-red">
            {error}
          </label>
        )}
      </div>
    );
  }
);

export default RadioButton;