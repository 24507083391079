/* eslint-disable max-len */
import * as React from "react";
import { SVGProps } from "react";

interface LogoProps extends SVGProps<SVGSVGElement> {
  color: "black" | "white";
  className?: string;
}

const MyneLogo = (props: LogoProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 156 39"
    {...props}
  >
    <path
      d="M116.8 38.702v-38h-6.4v23.6l-1.8-1.8L86.7.102h-.2v38h6.4v-23.5c.6.5 1.2 1.1 1.8 1.7l22 22.4h.1ZM155.3 38.102v-5.5h-17.8v-10.8h14.4v-5.3h-14.4v-10.3h17v-5.6H131v37.5h24.3ZM44 .602l13.5 22.8v14.7h6.6v-14.7L77.6.602h-7.3l-9.5 16.6-9.5-16.6H44ZM17.5 27.8c3.3-3.7 6.6-7.5 9.8-11.3l1.5-1.5v23H35V0h-.1L17.5 19.4.1.1H0v38h6.3v-23l1.4 1.4 9.8 11.3Z"
      fill={props.color === "black" ? "#000" : "#fff"}
    />
  </svg>
);

export default MyneLogo;